import React from 'react';
import { arrayOf, bool, object } from 'prop-types';
import Image from '../../../commons/image';

function ItemsSlide({ items = [], lazyLoad }) {
  const imgSize = '70';
  return (
    <div className="collections-slide-order-wrapper">
      <div className="collections-slide-items">
        {items.map((item) => (
          <a
            key={item.id}
            className="collections-slide-item"
            href={item.permalink}
          >
            <Image
              size={imgSize}
              src={item.picture.src}
              lazyLoad={lazyLoad}
              alt={item.title}
            />
          </a>
        ))}
      </div>
    </div>
  );
}

ItemsSlide.propTypes = {
  items: arrayOf(object),
  lazyLoad: bool.isRequired,
};

export default ItemsSlide;
