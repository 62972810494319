import React from 'react';
import { arrayOf, shape, string, bool } from 'prop-types';
import { CarouselSnapped, CarouselSnappedSlide } from '@andes/carousel-snapped';
import { injectI18n } from 'nordic/i18n';

import Section from '../../../commons/section';
import withTracker from '../../../commons/with-tracker';
import CollectionSlideDesktop from './collection-slide';

function CollectionsDesktop({ elements, lazyLoad, lowEnd }) {
  const slides = elements.map((element) => ({
    ...element,
    id: element.target_url,
    lazyLoad,
    lowEnd,
    alt: element.alt_text,
  }));

  return (
    <Section className="collections">
      <div className="container row">
        <CarouselSnapped slidesPerView={3} pagination={false} spacing={16}>
          {slides.map((item) => (
            <CarouselSnappedSlide key={item.id} className="slide-card">
              <CollectionSlideDesktop {...item} />
            </CarouselSnappedSlide>
          ))}
        </CarouselSnapped>
      </div>
    </Section>
  );
}

CollectionsDesktop.propTypes = {
  elements: arrayOf(
    shape({
      target_url: string.isRequired,
    }),
  ).isRequired,
  lazyLoad: bool.isRequired,
  lowEnd: bool.isRequired,
};

export default injectI18n(withTracker(CollectionsDesktop));
